const defaultTheme = require('tailwindcss/defaultTheme')
const colors = require('tailwindcss/colors')

const orange = {
    50: '#FFF6ED',
    100: '#FFE4CA',
    150: '#FCE8D3', // QoL page 2 chart orange background
    200: '#FDCD9E',
    300: '#FBB675',
    400: '#F9A14C',
    500: '#F78D1E',
    600: '#DF801B',
    700: '#CB7517',
    800: '#BB481B',
    900: '#7C3017',
    lighter: '#F78D1E',
    light: '#CB7517',
    darker: '#7C3017',
    DEFAULT: '#BB481B',
}

module.exports = {
    content: [
        './js/**/*.(js|vue)',
        '../views/**/*.blade.php',
        '../fieldsets/**/*.yaml',
        '../forms/**/*.yaml',
        '../../content/**/*.yaml',
        '../../content/**/*.md',
        '../views/**/*.antlers.html',
    ],
    darkMode: 'class',
    theme: {
        extend: {
            gridTemplateRows: {
                '1': 'repeat(1, minmax(0, 1fr))',
                '2': 'repeat(2, minmax(0, 1fr))',
                '3': 'repeat(3, minmax(0, 1fr))',
                '4': 'repeat(4, minmax(0, 1fr))',
                '5': 'repeat(5, minmax(0, 1fr))',
                '6': 'repeat(6, minmax(0, 1fr))',
                '7': 'repeat(7, minmax(0, 1fr))',
                '8': 'repeat(8, minmax(0, 1fr))',
                '9': 'repeat(9, minmax(0, 1fr))',
                '10': 'repeat(10, minmax(0, 1fr))',
                '11': 'repeat(11, minmax(0, 1fr))',
                '12': 'repeat(12, minmax(0, 1fr))',
                '13': 'repeat(13, minmax(0, 1fr))',
                '14': 'repeat(14, minmax(0, 1fr))',
                '15': 'repeat(15, minmax(0, 1fr))',
                '16': 'repeat(16, minmax(0, 1fr))',
            },
            fontFamily: {
                'museo': ['museo', 'sans-serif'],
                'museo-sans': ['museo-sans', 'sans-serif'],
                'museo-sans-condensed': ['museo-sans-condensed', 'sans-serif'],
                'nexa-heavy': ['nexa-heavy', 'sans-serif'],
                'whitney-book': ['whitney-book', 'sans-serif'],
                'sans': ['Nunito', ...defaultTheme.fontFamily.sans],
            },
            fontSize: {
                'large-base': '1.125rem'
            },
            colors: {
                transparent: 'transparent',
                current: 'currentColor',
                primary: {
                    50: '#F6F3FF',
                    100: '#CBBED5',
                    200: '#A68DB4',
                    300: '#886597',
                    400: '#6F417F',
                    500: '#591769',
                    600: '#4F0D5E',
                    700: '#450152',
                    800: '#3A0046',
                    900: '#591769',
                    lighter: '#886597',
                    darker: '#450152',
                    DEFAULT: '#591769',
                },
                secondary: orange,
                'primary-gray': {
                    300: '#EAEBEE',
                    400: '#E2E3E7',
                    500: '#C6C7CB',
                    600: '#9A9CA3',
                    700: '#74767F',
                    800: '#53565F',
                    900: '#343741',
                    DEFAULT: '#E2E3E7',
                    darker: '#343741',
                },
                gray: {
                    ...colors.gray,
                    150: '#E6E6E6', // for QoL 2nd page gray chart titles
                    darker: colors.gray[900],
                    lighter: colors.gray[500],
                    light: colors.gray[600],
                    DEFAULT: colors.gray[700],
                },
                red: {
                    ...colors.red,
                    DEFAULT: colors.red[600],
                },
                orange: orange,
                yellow: {
                    ...colors.yellow,
                    DEFAULT: colors.yellow[300],
                },
                green: {
                    50: '#F8FAEB',
                    100: '#E9F1CD',
                    150: '#EBF4D9', // Qol page 2 chart green background
                    200: '#D9E8AC',
                    300: '#C9DF8D',
                    400: '#B9D66D',
                    500: '#AACD4F',
                    600: '#8FAD40',
                    700: '#748E31',
                    800: '#5A7023',
                    900: '#415215',
                    lighter: '#B9D66D',
                    darker: '#8FAD40',
                    DEFAULT: '#AACD4F',
                },
                blue: {
                    ...colors.sky,
                    150: '#D0E5F4', // Qol Page 2 chart blue background
                    DEFAULT: colors.sky[500],
                },
                purple: {
                    ...colors.primary,
                    150: '#DED0E1',
                    850: '#5A1769',
                },

                /*
                 * Quality of Life colors
                 */
                'qol1-header': '#104767',

                qol1: {
                    topicBorder: '#5a2569',
                    topicBg: '#ddcfe0',
                    headingBg: '#e6e6e6',
                    headingMain: '#5a1769',
                    headingBorder: '#5a1769',
                    headingShadow: '#999999',
                    circleButtonMain: '#5a1769',
                    infoBoxText: '#5a1769',
                    infoBoxShadow: '#ababab',
                    infoBoxBorder: '#5a1769',
                },
                'qol1-full': {
                    headingBg: '#ded0e1',
                    headingMain: '#5a1769',
                    headingBorder: '#5a1769',
                    headingShadow: '#5a1769',
                    circleButtonMain: '#5a1769',
                    infoBoxText: '#5a1769',
                    infoBoxShadow: '#926d9c',
                    infoBoxBorder: '#5a1769',
                },
                qol2: {
                    topicBorder: '#b96a16',
                    topicBg: '#fae6cf',
                    headingBg: '#e6e6e6',
                    headingMain: '#7c470f',
                    headingBorder: '#b96a16',
                    headingShadow: '#999999',
                    circleButtonMain: '#7c470f',
                    infoBoxText: '#b96a16',
                    infoBoxShadow: '#ababab',
                    infoBoxBorder: '#b96a16',
                },
                'qol2-full': {
                    headingBg: '#fce8d3',
                    headingMain: '#7c470f',
                    headingBorder: '#d7a77c',
                    headingShadow: '#faba78',
                    circleButtonMain: '#7c470f',
                    infoBoxText: '#b96a16',
                    infoBoxShadow: '#fcd1a5',
                    infoBoxBorder: '#b96a16',
                },
                qol3: {
                    topicBorder: '#186b9a',
                    topicBg: '#d2e7f6',
                    headingBg: '#e6e6e6',
                    headingMain: '#186b98',
                    headingBorder: '#33617a',
                    headingShadow: '#999999',
                    circleButtonMain: '#0e4767',
                    infoBoxText: '#208ccb',
                    infoBoxShadow: '#ababab',
                    infoBoxBorder: '#208ccb',
                },
                'qol3-full': {
                    headingBg: '#d0e5f4',
                    headingMain: '#0e4767',
                    headingBorder: '#0e4767',
                    headingShadow: '#186b98',
                    circleButtonMain: '#0e4767',
                    infoBoxText: '#186b98',
                    infoBoxShadow: '#a4d1ea',
                    infoBoxBorder: '#186b98',
                },
                qol4: {
                    topicBorder: '#56672f',
                    topicBg: '#edf4db',
                    headingBg: '#e6e6e6',
                    headingMain: '#556628',
                    headingBorder: '#556628',
                    headingShadow: '#999999',
                    circleButtonMain: '#556628',
                    infoBoxText: '#556628',
                    infoBoxShadow: '#ababab',
                    infoBoxBorder: '#556628',
                },
                'qol4-full': {
                    headingBg: '#ebf4d9',
                    headingMain: '#556628',
                    headingBorder: '#556628',
                    headingShadow: '#556628',
                    circleButtonMain: '#556628',
                    infoBoxText: '#556628',
                    infoBoxShadow: '#cbdf93',
                    infoBoxBorder: '#556628',
                },
            },
            boxShadow: {
                'vertical-scroll-up':  'inset 0 -20px 20px -20px rgb(0 0 0 / 0.5)',
                'vertical-scroll-both':  'inset 0 20px 20px -20px rgb(0 0 0 / 0.5), inset 0 -20px 20px -20px rgb(0 0 0 / 0.5)',
                'vertical-scroll-down':  'inset 0 20px 20px -20px rgb(0 0 0 / 0.5)',
            },
            dropShadow: {
                'down': '0 20px 0 #ababab',
                'angle-left': '15px 15px #ababab',
                'angle-right': '-15px 15px #ababab',
                'angle-right-sm': '10px 10px #ababab',
                'angle-left-sm': '-10px 10px #ababab',
            },
        },
    },
    variants: {
        extend: {},
    },
    safelist: [
        {
            pattern: /grid-rows-(1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16)/,
            variants: ['md', 'lg'],
        },
    ],
    plugins: [
        require('@tailwindcss/forms')({
            strategy: 'class',
        }),
        require('@tailwindcss/typography'),
        require('@tailwindcss/aspect-ratio'),
    ],
}
